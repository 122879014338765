import { h } from "preact";
import { useState } from "preact/hooks";

const TestCountEvent = () => {
  const [app, setApp] = useState("chat");
  const [key, setKey] = useState("unreadChat");
  const [value, setValue] = useState("0");

  return (
    <div className="tui-test-area">
      <div className="tui-count-event-input">
        <select
          className="tui-count-event-app"
          data-testid="tui-count-event-app-select"
          value={app}
          onChange={(evt) => setApp(evt.target.value)}
        >
          <option value="chat">chat</option>
        </select>
        <select
          className="tui-count-event-key"
          data-testid="tui-count-event-key-select"
          value={key}
          onChange={(evt) => setKey(evt.target.value)}
        >
          <option value="unreadChat">unreadChat</option>
          <option value="urgentChat">urgentChat</option>
        </select>
        <select
          className="tui-count-event-value"
          data-testid="tui-count-event-value-select"
          value={value}
          onChange={(evt) => {
            setValue(evt.target.value);
          }}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="10">10</option>
          <option value="100">100</option>
          <option value="1000">1000</option>
        </select>
      </div>
      <button
        className="tui-test-button"
        data-testid="tui-count-event-receive-button"
        onClick={() => {
          const event = new CustomEvent("test-count-event-receive", {
            detail: {
              eventCount: {
                app,
                key,
                value
              }
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Receive
      </button>
    </div>
  );
};

export default TestCountEvent;
