import { h } from "preact";
import { useState, useEffect } from "preact/hooks";

const TestIntegration = () => {
  const [appDiscovery, setAppDiscovery] = useState(undefined);
  const [hostUrl, setHostUrl] = useState(undefined);

  useEffect(() => {
    const appDiscMeta = document.querySelector('meta[name="application-discovery"]');
    if (appDiscMeta) {
      setAppDiscovery(appDiscMeta.content);
    }
  }, []);

  useEffect(() => {
    if (!appDiscovery) return;
    const url = new URL("https://host.sbx.emishealthsolutions.com");
    url.searchParams.append("appdisc", appDiscovery);
    setHostUrl(url);
  }, [appDiscovery]);

  return hostUrl ? (
    <div className="tui-test-integration">
      <a target="_blank" href={hostUrl} className="tui-test-integration-button">
        Test integration with EMIS-X
      </a>
    </div>
  ) : (
    <div className="tui-no-app-disc" />
  );
};

export default TestIntegration;
