import { h } from "preact";
import { useState, useEffect } from "preact/hooks";

const TestPatientContext = () => {
  const [patientId, setPatientId] = useState("");
  const [personId, setPersonId] = useState("");
  const [deferred, setDeferred] = useState(false);

  useEffect(() => {
    const event = new CustomEvent("test-patient-defer-updates", { detail: { deferred } });
    document.dispatchEvent(event);
  }, [deferred]);

  return (
    <div className="tui-test-area">
      <div className="tui-test-patient-context">
        <input
          data-testid="tui-patient-id-input"
          className="tui-test-patient-id"
          aria-label="Patient Identifier"
          placeholder="Patient Identifier"
          type="text"
          value={patientId}
          onInput={(evt) => setPatientId(evt.target.value)}
        />
        <span>(or)</span>&nbsp;&nbsp;&nbsp;
        <input
          data-testid="tui-person-id-input"
          className="tui-test-person-id"
          aria-label="Person Identifier"
          placeholder="Person Identifier"
          type="text"
          value={personId}
          onInput={(evt) => setPersonId(evt.target.value)}
        />
      </div>
      <button
        className="tui-test-button"
        data-testid="tui-patient-swap-button"
        onClick={() => {
          const event = new CustomEvent("test-patient-swap", { detail: { patientId, personId } });
          document.dispatchEvent(event);
        }}
      >
        Swap Patient
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-patient-updated-button"
        onClick={() => {
          const event = new Event("test-patient-updated");
          document.dispatchEvent(event);
        }}
      >
        Record Updated
      </button>
      <label className="tui-test-checkbox-label">
        Defer update
        <input
          className="tui-test-checkbox"
          data-testid="tui-patient-emis-defer-checkbox"
          type="checkbox"
          checked={deferred}
          onClick={() => {
            setDeferred((deferred) => !deferred);
          }}
        />
      </label>
    </div>
  );
};

export default TestPatientContext;
