import { h } from "preact";

const TestTimeout = () => {
  return (
    <div className="tui-test-area">
      <div className="tui-simulate-timeout">
        <button
          className="tui-test-button"
          data-testid="tui-simulate-timeout"
          onClick={() => {
            const event = new CustomEvent("test-simulate-timeout");
            document.dispatchEvent(event);
          }}
        >
          Simulate timeout
        </button>
      </div>
    </div>
  );
};

export default TestTimeout;
