import { h } from "preact";
import { useState } from "preact/hooks";

const TestDiscardDialog = () => {
  const [applicationName, setApplicationName] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [size, setSize] = useState("medium");
  const [type, setType] = useState("warning");
  const [popupPreamble, setPopupPreamble] = useState(undefined);
  const [popupContentTitle, setPopupContentTitle] = useState(undefined);
  const [popupContentContent, setPopupContentContent] = useState(undefined);

  return (
    <div className="tui-test-area">
      <div className="tui-notification-input">
        <input
          data-testid="tui-dialog-application-name-input"
          className="tui-test-application-name"
          aria-label="Discard Application Name"
          placeholder="Discard Application Name"
          type="text"
          value={applicationName}
          onInput={(evt) => setApplicationName(evt.target.value)}
        />
        <input
          data-testid="tui-dialog-message-input"
          className="tui-test-message"
          aria-label="Discard Message"
          placeholder="Discard Message"
          type="text"
          value={message}
          onInput={(evt) => setMessage(evt.target.value)}
        />
        <select data-testid="tui-dialog-type-select" value={type} onChange={(evt) => setType(evt.target.value)}>
          <option value="error">Error</option>
          <option value="information">Information</option>
          <option value="success">Success</option>
          <option value="severe">Severe</option>
          <option value="warning">Warning</option>
        </select>
        <select data-testid="tui-dialog-size-select" value={size} onChange={(evt) => setSize(evt.target.value)}>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="x-large">X-Large</option>
        </select>
      </div>
      <div className="tui-notification-input">
        <input
          data-testid="tui-dialog-popup-preamble-input"
          className="tui-test-popup-preamble"
          aria-label="Popup Preamble"
          placeholder="Popup Preamble"
          type="text"
          value={popupPreamble}
          onInput={(evt) => setPopupPreamble(evt.target.value)}
        />
        <input
          data-testid="tui-dialog-popup-content-title-input"
          className="tui-test-popup-content-title"
          aria-label="Popup Title"
          placeholder="Popup Title"
          type="text"
          value={popupContentTitle}
          onInput={(evt) => setPopupContentTitle(evt.target.value)}
        />
        <input
          data-testid="tui-dialog-popup-content-content-input"
          className="tui-test-popup-content-content"
          aria-label="Popup Content"
          placeholder="Popup Content"
          type="text"
          value={popupContentContent}
          onInput={(evt) => setPopupContentContent(evt.target.value)}
        />
      </div>
      <button
        className="tui-test-button"
        data-testid="tui-discard-intercept-button"
        onClick={() => {
          const event = new CustomEvent("test-discards", {
            detail: {
              applicationName,
              message,
              popupPreamble,
              popupContent: { title: popupContentTitle, message: popupContentContent },
              size,
              type,
              intercept: true
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Intercept
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-discard-clear-button"
        onClick={() => {
          const event = new CustomEvent("test-discards", {
            detail: {
              intercept: false
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Clear
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-discard-immediate-button"
        onClick={() => {
          const event = new CustomEvent("test-discards", {
            detail: {
              applicationName,
              message,
              popupPreamble,
              popupContent: { title: popupContentTitle, message: popupContentContent },
              size,
              type,
              intercept: true,
              immediate: true
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Immediate Intercept
      </button>
    </div>
  );
};

export default TestDiscardDialog;
