import { h, Component } from "preact";
import Popup from "./popup.component";

export default class FullUI extends Component {
  constructor() {
    super();

    this.state = {
      showingPopup: false,
      showApp: false
    };
  }

  componentDidMount() {
    window.addEventListener("acp:authSuccess", this.showApp);
  }
  componentWillUnmount() {
    windowm.removeEventListener("acp:authSuccess", this.showApp);
  }

  doUpdate = () => this.forceUpdate();
  render(props, state) {
    // Do not render if ran in electron
    if (window.interopApi) return;
    if (!this.state.showApp) return <div></div>;

    return (
      <div>
        <button data-testid="tui-open-test-ui-button" onClick={this.toggleTrigger} className="tui-unstyled tui-trigger">
          {"{\u00B7\u00B7\u00B7}"}
        </button>
        {state.showingPopup && <Popup close={this.toggleTrigger} />}
      </div>
    );
  }

  showApp = () => {
    this.setState({ showApp: true });
  };

  toggleTrigger = () => {
    this.setState((prevState) => ({
      showingPopup: !prevState.showingPopup
    }));
  };
}
