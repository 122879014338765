import { h, Component } from "preact";
import Test from "./test-components";
import TestIntegration from "./test-integration";

export default class Popup extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this.keydownListener);
    window.addEventListener("test-ui:change", this.doUpdate);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.keydownListener);
    window.removeEventListener("test-ui:change", this.doUpdate);
  }
  doUpdate = () => this.forceUpdate();
  render(props) {
    return (
      <div className="tui-popup">
        <div className="tui-header">
          <div>
            <h2>Integration Tests</h2>
            <p>
              This developer tool allows you to test application context usually supplied by an external source .{" "}
              <a target="_blank" href="https://github.com/emisgroup/acp-cdn/tree/develop/dev/test-ui">
                See documentation for more info
              </a>
              .
            </p>
          </div>
          <button data-testid="tui-close-form-button" className="tui-unstyled" onClick={props.close}>
            {"\u24E7"}
          </button>
        </div>
        <Test></Test>
        <TestIntegration></TestIntegration>
      </div>
    );
  }
  keydownListener = (evt) => {
    if (evt.key === "Escape" && this.props.close) {
      this.props.close();
    }
  };
}
